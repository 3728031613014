import React, { useEffect, useState } from "react"
import { Modal, DropdownButton, Dropdown, Row, Col } from "react-bootstrap"
import moment from "moment"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { confirmAlert } from "react-confirm-alert"
import { FormatMoney } from "../../common/helpers"
import AddEditPayment from "./AddEditPayment"

//Translation
import { useTranslation } from "react-i18next"
import { Routes } from "../../routes"

//Component
const Payments = props => {
  const [state, setState] = useState({
    UI: {
      Validated: false,
    },
  })
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [selectedObj, setSelectedObj] = useState(null)
  const toggleAddEdit = async (obj, reload) => {
    if (obj) {
      setSelectedObj(obj)
    }
    setShowAddEdit(!showAddEdit)
    if (reload) {
      await request()
    }
  }

  const { t } = useTranslation("global")

  const deleteConfirm = async obj => {
    let currentUser = parsedUser()

    confirmAlert({
      title: t("confirm"),
      message: t("questions.sure_delete") + obj.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "invoice/deletepayment?id=" + obj.id + "&UserId=" + currentUser.ID
            )
            await request()
          },
        },
        {
          label: t("no"),
          //     onClick: () => alert('Click No')
        },
      ],
    })
  }

  const request = async () => {
    let Query = "id=" + props.model.id

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("invoice/payments", Query)

    if (requestAPI.data.status === "ok") {
      setState({
        ...state,
        Records: requestAPI.data.response,
        //   Quantity: requestAPI.data.quantityOfRecords,
        //     Page: requestAPI.data.page,
        TotalPages: requestAPI.data.quantityOfPages,
      })
    }
  }

  useEffect(() => {
    request()
  }, [])
  let total = 0
  return (
    <>
      {showAddEdit ? (
        <AddEditPayment
          toggle={toggleAddEdit}
          show={showAddEdit}
          payments={state.Records}
          model={selectedObj}
          parentModel={props.model}
        ></AddEditPayment>
      ) : null}
      <Modal
        show={props.show}
        onHide={props.toggle}
        size="lg"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("payments")}</Modal.Title>
          <button
            className=" mx-1 btn btn-sm btn-primary"
            type="button"
            onClick={() =>
              toggleAddEdit({
                id: "",
                invoiceId: props.id,
                payment: null,
                date: moment().format(),
                created: moment().format(),
                deleted: null,
                updated: null,
                createdId: "",
                deletedId: null,
                updatedId: null,
                note: "",
                financialAccountId: null,
                currencyId: "",
                exchangeRate: 1,
                retention: 0,
              })
            }
          >
            <i className="fa fa-plus"></i>
          </button>
        </Modal.Header>

        <Modal.Body>
          <Row className="mx-0">
            <Col>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("currency")}</th>
                    <th>{t("payment")}</th>
                    <th>{t("retention")}</th>
                    <th>{t("subtotal")}</th>
                  </tr>
                </thead>
                <tbody>
                  {state &&
                    state.Records &&
                    state.Records.map((item, index) => {
                      total += item.payment + item.retention ?? 0
                      return (
                        <tr key={index} className="clickable">
                          <td>
                            <small>
                              {moment(item.date).format("MMM DD, YYYY")}
                            </small>
                          </td>
                          <td>{item.currency.name}</td>
                          <td onClick={() => toggleAddEdit(item)}>
                            {FormatMoney(item.payment)}
                          </td>
                          <td onClick={() => toggleAddEdit(item)}>
                            {FormatMoney(item.retention)}
                          </td>
                          <td>
                            {FormatMoney(item.payment + item.retention ?? 0)}
                          </td>
                          <td className="text-right">
                            <DropdownButton
                              id="dropdown-item-button"
                              title={<i className="fa fa-ellipsis-v"></i>}
                            >
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  window.open(
                                    `${Routes.PrintPayment.clean}${item.id}`
                                  )
                                }}
                              >
                                {t("print")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => toggleAddEdit(item)}
                              >
                                {t("edit")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => deleteConfirm(item)}
                              >
                                {t("delete")}
                              </Dropdown.Item>
                            </DropdownButton>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <th>{FormatMoney(total)}</th>
                  </tr>
                </tfoot>
              </table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Payments
