import React, { useEffect, createRef, useState } from "react"
import { Row } from "react-bootstrap"

import API from "../../common/API"

import moment from "moment-timezone"

import interactionPlugin from "@fullcalendar/interaction"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import { parsedUser } from "../../common/GetCurrentUser"
import AddEditActivity from "../Agenda/AddEditActivity"

import ActivityDetails from "../Agenda/ActivityDetails"
import { useTranslation } from "react-i18next"
import ToDoList from "../ToDo/ToDoList"

const CalendarOverview = props => {
  const { t } = useTranslation("global")
  const fullCalendarRef = createRef()
  const [CurrentDate, setCurrentDate] = useState(new Date())
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [state, setState] = useState({
    Events: [],
    UI: {
      OnlyForTheUser: true,
      ActivitySelected: null,
      selectedId: null,
    },
  })

  const request = async () => {
    let currentUser = parsedUser()
    let query = ""
    if (!currentUser) return

    if (state.UI.OnlyForTheUser) {
      query = "AssignedToId=" + currentUser.ID
    } else {
      query = "OrganizationId=" + currentUser.OrganizationId + "&AssignedToId="
    }

    const requestAPI = await API.getAction("Appointments/List?" + query)

    if (requestAPI.data.status === "ok") {
      let parsedEvents = []
      requestAPI.data.response.forEach(item => {
        parsedEvents.push({
          id: item.id,
          date: item.date,
          title: item.name,
        })
      })
      setState({
        ...state,
        Events: parsedEvents,
      })
    }
  }
  const toggleShowDetails = selected => {
    setState({
      ...state,
      UI: {
        ...state.UI,
        selectedId: selected,
      },
    })

    setShowDetails(!showDetails)
  }
  const moveMonth = obj => {
    let d = moment(CurrentDate).add(obj, "months")
    setCurrentDate(d.format())
    if (fullCalendarRef.current !== null) {
      fullCalendarRef.current.getApi().gotoDate(d.format())
      fullCalendarRef.current.getApi().select(d.format())
    }
    //We must request current month events :)
  }

  useEffect(() => {
    request()
  }, [])

  const toggleAddEdit = async (opt, reload) => {
    setState({
      ...state,
      UI: {
        ActivitySelected: opt,
      },
    })
    setShowAddEdit(!showAddEdit)

    if (showAddEdit) {
      await request()
    }
  }

  return (
    <Row>
      {showAddEdit ? (
        <AddEditActivity
          toggle={toggleAddEdit}
          show={showAddEdit}
          model={state.UI.ActivitySelected}
        />
      ) : null}

      <div className="col-12 col-lg-9 col-md-8  ">
        <div className="card shadow-sm calendarContainer full">
          <div className="card-header  px-2">
            <div className="row mx-0">
              <div className="col-auto pt-1">
                <h4 className="text-uppercase">
                  {moment(CurrentDate).format("MMM YYYY")}
                </h4>
              </div>
              <div className="col">
                <div className="btn-group">
                  <button
                    className="btn btn-sm btn-secondary "
                    onClick={() => {
                      setCurrentDate(moment())
                    }}
                  >
                    Hoy
                  </button>
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={() => {
                      moveMonth(-1)
                    }}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={() => {
                      moveMonth(1)
                    }}
                  >
                    <i className="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
              <div className="col text-right pt-1">
                <div className="text-right btn-group px-2">
                  {/* <button className="btn btn-sm btn-primary">
                    <i className="fas fa-filter"></i>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body px-0 py-0">
            <FullCalendar
              ref={fullCalendarRef}
              className="calendarStyle"
              initialDate={CurrentDate}
              headerToolbar={false}
              events={state.Events}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              eventClick={i => {
                console.log("click", i, i.event.id)
                toggleAddEdit(i.event, "edit", false)
                //     props.history.push(Routes.ActivityDetails.clean+i.event.id);
              }}
              dateClick={i => {
                console.log("click ====", i)
                var theUser = parsedUser()

                toggleAddEdit(
                  {
                    name: "",
                    notes: "",

                    status: 0,
                    createdId: theUser.ID,
                    updatedId: "",
                    invites: "",
                    customerId: null,
                    location: "",
                    date: moment(i.date).format("YYYY-MM-DD"),
                    created: moment(new Date()).format("YYYY-MM-DDTHH:mm:SSZ"),
                    id: "",
                    organizationId: theUser.OrganizationId,
                    appointmentTypeId: null,
                    assignedToId: theUser.ID,
                    type: 1,
                  },
                  false
                )
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-12 col-md-4 col-lg-3 ">
        <ToDoList />
      </div>
    </Row>
  )
}

export default CalendarOverview
