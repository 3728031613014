import { useEffect, useState } from "react"
import { Gallery, Item } from "react-photoswipe-gallery"
import "photoswipe/dist/photoswipe.css"
import API from "../../../common/API"
import { parsedUser } from "../../../common/GetCurrentUser"
import Settings from "../../../common/Settings"

const DiagnosticGallery = props => {
  const user = parsedUser()
  const [records, setRecords] = useState([])
  ///File/ListFiles?OrganizationId=f64df772-a350-42ea-90ec-787d260edeb4&WorkshopRecordId=8cc529b3-db0b-4a98-8a95-ac31ca02471d
  const bindDataRemote = async () => {
    const ddata = await API.getAction(
      `file/workshopFiles?WorkshopRecordId=${props.id}`
    )
    if (ddata.data.status === "ok") {
      let a = ddata.data.response.filter(
        x =>
          x.path.includes(".jpeg") ||
          x.path.includes(".jpg") ||
          x.path.includes(".png")
      )
      console.log(a)

      setRecords(a)
    }
  }
  useEffect(() => {
    bindDataRemote()
  }, [props.id])

  return (
    <Gallery>
      {records.map((image: any, i: number) => {
        let finalPath = Settings.BasePath + "files/" + image.path

        console.log(finalPath, "rutas")
        return (
          <Item key={i} original={finalPath} thumbnail={finalPath}>
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src={finalPath}
                className="w-25 m-1"
              />
            )}
          </Item>
        )
      })}
    </Gallery>
  )
}

export default DiagnosticGallery
