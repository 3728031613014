import moment from "moment"
import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { FormatMoney } from "../../common/helpers"
import Settings from "../../common/Settings"
import "../../css/app_print.css"
import { useTranslation } from "react-i18next"

const PrintPayment = props => {
  const [state, setState] = useState(null)
  const [subTotalGeneral, setSubtotalGeneral] = useState(0)
  const [TotalTaxes, setTotalTaxes] = useState(0)
  const { t } = useTranslation("global")
  const request = async () => {
    let requestAPI = await API.getAction(
      "invoice/PaymentDetails?Id=" + props.match.params.id
    )

    if (requestAPI.data.status === "ok") {
      console.log(requestAPI.data.response)
      setState(requestAPI.data.response)
    }
  }

  useEffect(() => {
    request()

    setTimeout(() => {
      if (window.location.href.includes("printExecute")) {
        window.print()
      }
    }, [5000])
  }, [])

  if (state === null) {
    return <></>
  }
  return (
    <>
      <section className="invoice bg-white">
        <input
          type="hidden"
          id="CurrencyExchangeRateSelected"
          name="CurrencyExchangeRateSelected"
          value="1"
        />

        <div className="row m-2">
          <div className="col-8">
            <h3 className="page-header text-left">
              {state.invoice.organization.logo ? (
                <img
                  src={`${Settings.BasePath}Files/${state.invoice.organization.logo}?h=100`}
                  title={state.invoice.organization.name}
                  alt={state.invoice.organization.name}
                  style={{ maxHeight: "100px" }}
                />
              ) : (
                state.invoice.organization.name
              )}
            </h3>
          </div>
          <div className="col-4 text-right">
            <b>Factura # {state.invoice.invoiceNumber}</b>

            {state.paidStatus === 2 ? (
              <span className="badge bg-success d-print-none ms-2">Pagada</span>
            ) : (
              <span className="badge bg-secondary d-print-none ms-2">
                Pendiente
              </span>
            )}
            {state.invoice.expirationDate ? (
              <>
                <br />
                <span className="ms-2">
                  {moment(state.expirationDate).format("MMM DD, YYYY")}
                </span>
              </>
            ) : null}
          </div>
        </div>

        <div className="row invoice-info m-2">
          <div className="col-6">
            <div className="col-12 ">
              <address>
                <strong>{state.invoice.organization?.name}</strong>
                <br />
                {state.invoice.organization.identifier}
                <br />
                {`${state.invoice.organization.address ?? ""}, ${
                  state.invoice.organization.city ?? ""
                }, ${state.invoice.organization.zipCode ?? ""}, ${
                  state.invoice.organization.country ?? ""
                }`.replace(", ,", ", ")}
                <br />

                {state.invoice.organization.phone ? (
                  <>
                    {`Teléfono: ${state.invoice.organization.phone}`}
                    <br />
                  </>
                ) : null}

                {state.invoice.organization.email
                  ? `Email: ${state.invoice.organization.email}`
                  : null}
              </address>
            </div>
            <div className="col-sm-12 col-md-12 CustomerInfo">
              <address>
                <strong>{`${state.invoice.customerName} ${
                  state.invoice.customerName ?? ""
                }`}</strong>
                <br />
                {state.invoice.customerIdentifier}
                <br />
                {`${state.invoice.customerAddress ?? ""}`}
                <br />
                {state.invoice.customerPhone ? (
                  <>
                    {`Teléfono: ${state.invoice.customerPhone}`}
                    <br />
                  </>
                ) : null}

                {state.invoice.customerEmail
                  ? `Email: ${state.invoice.customerEmail}`
                  : null}
              </address>
            </div>
          </div>

          <div className="col-6 text-right">
            <b>Fecha: </b>
            {moment(state.created).format("MMM DD, YYYY")}
            <br />

            <b>Hora: </b>

            {moment(state.created).format("HH:mm:ss ")}
            {state.invoice.invoiceType ? (
              <>
                <br />
                <b>Tipo: </b> {state.invoice.invoiceType}
              </>
            ) : null}

            {state.invoice.currency ? (
              <>
                <br />
                <b>Moneda: </b> {state.invoice.currency}
              </>
            ) : null}
          </div>
        </div>

        <div className="row m-2">
          <div className="col-12 table-responsive">
            <table id="details" className="table table-bordered">
              <thead>
                <tr>
                  <th colSpan={3} className="text-center ">
                    <h2>Recibo de Ingreso</h2>
                  </th>
                </tr>
                <tr>
                  <th className="col-md-2">{t("Date")}</th>

                  <th className="col-md-1 text-end">Sub-total</th>
                  <th className="col-md-1 text-end">Retención</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{moment(state.created).format("MMM DD, YYYY")}</td>

                  <td className="text-end">{FormatMoney(state.payment)}</td>
                  <td className="text-end">{FormatMoney(state.retention)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-2">
          <div className="col-6">
            {state.note ? (
              <p
                className="text-muted well well-sm no-shadow small"
                dangerouslySetInnerHTML={{
                  __html: state.note.replace(/\n/g, "<br />"),
                }}
              ></p>
            ) : null}
          </div>

          <div className="col-6">
            <p className="lead">Balance</p>

            <div className="table-responsive">
              <table className="table summary">
                <tbody>
                  <tr className="total">
                    <th>Total:</th>
                    <td className="text-right">
                      <b> {FormatMoney(state.payment - state.retention)}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="d-print-none ">
              <button
                className="btn btn-primary btn-block "
                type="button"
                onClick={() => {
                  window.print()
                }}
              >
                Imprimir
              </button>
            </p>
          </div>
          {state.disclaimer ? (
            <div className="col-12">
              <div
                className="text-muted well well-sm no-shadow small smallest"
                dangerouslySetInnerHTML={{
                  __html: state.disclaimer.replace(/\n/g, "<br />"),
                }}
              ></div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  )
}

export default PrintPayment
